//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { getCurrentUser } from '@nextcloud/auth';
import moment from '@nextcloud/moment';
import ActionButton from '@nextcloud/vue/dist/Components/ActionButton';
import Actions from '@nextcloud/vue/dist/Components/Actions';
import ActionSeparator from '@nextcloud/vue/dist/Components/ActionSeparator';
import Avatar from '@nextcloud/vue/dist/Components/Avatar';
import RichContenteditable from '@nextcloud/vue/dist/Components/RichContenteditable';
import RichEditorMixin from '@nextcloud/vue/dist/Mixins/richEditor';
import Moment from './Moment';
import CommentMixin from '../mixins/CommentMixin';
export default {
  name: 'Comment',
  components: {
    ActionButton: ActionButton,
    Actions: Actions,
    ActionSeparator: ActionSeparator,
    Avatar: Avatar,
    Moment: Moment,
    RichContenteditable: RichContenteditable
  },
  mixins: [RichEditorMixin, CommentMixin],
  inheritAttrs: false,
  props: {
    actorDisplayName: {
      type: String,
      required: true
    },
    actorId: {
      type: String,
      required: true
    },
    creationDateTime: {
      type: String,
      default: null
    },

    /**
     * Force the editor display
     */
    editor: {
      type: Boolean,
      default: false
    },

    /**
     * Provide the autocompletion data
     */
    autoComplete: {
      type: Function,
      required: true
    }
  },
  data: function data() {
    return {
      expanded: false,
      // Only change data locally and update the original
      // parent data when the request is sent and resolved
      localMessage: ''
    };
  },
  computed: {
    /**
     * Is the current user the author of this comment
     *
     * @return {boolean}
     */
    isOwnComment: function isOwnComment() {
      return getCurrentUser().uid === this.actorId;
    },

    /**
     * Rendered content as html string
     *
     * @return {string}
     */
    renderedContent: function renderedContent() {
      if (this.isEmptyMessage) {
        return '';
      }

      return this.renderContent(this.localMessage);
    },
    isEmptyMessage: function isEmptyMessage() {
      return !this.localMessage || this.localMessage.trim() === '';
    },
    timestamp: function timestamp() {
      // seconds, not milliseconds
      return parseInt(moment(this.creationDateTime).format('x'), 10) / 1000;
    }
  },
  watch: {
    // If the data change, update the local value
    message: function message(_message) {
      this.updateLocalMessage(_message);
    }
  },
  beforeMount: function beforeMount() {
    // Init localMessage
    this.updateLocalMessage(this.message);
  },
  methods: {
    /**
     * Update local Message on outer change
     *
     * @param {string} message the message to set
     */
    updateLocalMessage: function updateLocalMessage(message) {
      this.localMessage = message.toString();
    },

    /**
     * Dispatch message between edit and create
     */
    onSubmit: function onSubmit() {
      var _this = this;

      // Do not submit if message is empty
      if (this.localMessage.trim() === '') {
        return;
      }

      if (this.editor) {
        this.onNewComment(this.localMessage.trim());
        this.$nextTick(function () {
          // Focus the editor again
          _this.$refs.editor.$el.focus();
        });
        return;
      }

      this.onEditComment(this.localMessage.trim());
    },
    onExpand: function onExpand() {
      this.expanded = true;
    }
  }
};