//
//
//
//
//
//
import moment from '@nextcloud/moment';
export default {
  name: 'Moment',
  props: {
    timestamp: {
      type: Number,
      required: true
    },
    format: {
      type: String,
      default: 'LLL'
    }
  },
  computed: {
    title: function title() {
      return moment.unix(this.timestamp).format(this.format);
    },
    formatted: function formatted() {
      return moment.unix(this.timestamp).fromNow();
    }
  }
};