var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: !_vm.deleted,
          expression: "!deleted",
        },
      ],
      staticClass: "comment",
      class: { "comment--loading": _vm.loading },
    },
    [
      _c(
        "div",
        { staticClass: "comment__header" },
        [
          _c("Avatar", {
            staticClass: "comment__avatar",
            attrs: {
              "display-name": _vm.actorDisplayName,
              user: _vm.actorId,
              size: 32,
            },
          }),
          _vm._v(" "),
          _c("span", { staticClass: "comment__author" }, [
            _vm._v(_vm._s(_vm.actorDisplayName)),
          ]),
          _vm._v(" "),
          _vm.isOwnComment && _vm.id && !_vm.loading
            ? _c(
                "Actions",
                { staticClass: "comment__actions" },
                [
                  !_vm.editing
                    ? [
                        _c(
                          "ActionButton",
                          {
                            attrs: {
                              "close-after-click": true,
                              icon: "icon-rename",
                            },
                            on: { click: _vm.onEdit },
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t" +
                                _vm._s(_vm.t("comments", "Edit comment")) +
                                "\n\t\t\t\t"
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("ActionSeparator"),
                        _vm._v(" "),
                        _c(
                          "ActionButton",
                          {
                            attrs: {
                              "close-after-click": true,
                              icon: "icon-delete",
                            },
                            on: { click: _vm.onDeleteWithUndo },
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t" +
                                _vm._s(_vm.t("comments", "Delete comment")) +
                                "\n\t\t\t\t"
                            ),
                          ]
                        ),
                      ]
                    : _c(
                        "ActionButton",
                        {
                          attrs: { icon: "icon-close" },
                          on: { click: _vm.onEditCancel },
                        },
                        [
                          _vm._v(
                            "\n\t\t\t\t" +
                              _vm._s(_vm.t("comments", "Cancel edit")) +
                              "\n\t\t\t"
                          ),
                        ]
                      ),
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.id && _vm.loading
            ? _c("div", { staticClass: "comment_loading icon-loading-small" })
            : _vm.creationDateTime
            ? _c("Moment", {
                staticClass: "comment__timestamp",
                attrs: { timestamp: _vm.timestamp },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _vm.editor || _vm.editing
        ? _c(
            "div",
            { staticClass: "comment__editor " },
            [
              _c("RichContenteditable", {
                ref: "editor",
                attrs: {
                  "auto-complete": _vm.autoComplete,
                  contenteditable: !_vm.loading,
                  value: _vm.localMessage,
                },
                on: {
                  "update:value": _vm.updateLocalMessage,
                  submit: _vm.onSubmit,
                },
              }),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip",
                    value: _vm.t("comments", "Post comment"),
                    expression: "t('comments', 'Post comment')",
                  },
                ],
                staticClass: "comment__submit",
                class: _vm.loading ? "icon-loading-small" : "icon-confirm",
                attrs: {
                  type: "submit",
                  disabled: _vm.isEmptyMessage,
                  value: "",
                },
                on: { click: _vm.onSubmit },
              }),
            ],
            1
          )
        : _c("div", {
            staticClass: "comment__message",
            class: { "comment__message--expanded": _vm.expanded },
            domProps: { innerHTML: _vm._s(_vm.renderedContent) },
            on: { click: _vm.onExpand },
          }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }