var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "comments", class: { "icon-loading": _vm.isFirstLoading } },
    [
      _c(
        "Comment",
        _vm._b(
          {
            staticClass: "comments__writer",
            attrs: {
              "auto-complete": _vm.autoComplete,
              editor: true,
              "ressource-id": _vm.ressourceId,
            },
            on: { new: _vm.onNewComment },
          },
          "Comment",
          _vm.editorData,
          false
        )
      ),
      _vm._v(" "),
      !_vm.isFirstLoading
        ? [
            !_vm.hasComments && _vm.done
              ? _c("EmptyContent", { attrs: { icon: "icon-comment" } }, [
                  _vm._v(
                    "\n\t\t\t" +
                      _vm._s(
                        _vm.t(
                          "comments",
                          "No comments yet, start the conversation!"
                        )
                      ) +
                      "\n\t\t"
                  ),
                ])
              : _vm._l(_vm.comments, function (comment) {
                  return _c(
                    "Comment",
                    _vm._b(
                      {
                        key: comment.props.id,
                        staticClass: "comments__list",
                        attrs: {
                          "auto-complete": _vm.autoComplete,
                          message: comment.props.message,
                          "ressource-id": _vm.ressourceId,
                          "user-data": _vm.genMentionsData(
                            comment.props.mentions
                          ),
                        },
                        on: {
                          "update:message": function ($event) {
                            return _vm.$set(comment.props, "message", $event)
                          },
                          delete: _vm.onDelete,
                        },
                      },
                      "Comment",
                      comment.props,
                      false
                    )
                  )
                }),
            _vm._v(" "),
            _vm.loading && !_vm.isFirstLoading
              ? _c("div", { staticClass: "comments__info icon-loading" })
              : _vm.hasComments && _vm.done
              ? _c("div", { staticClass: "comments__info" }, [
                  _vm._v(
                    "\n\t\t\t" +
                      _vm._s(_vm.t("comments", "No more messages")) +
                      "\n\t\t"
                  ),
                ])
              : _vm.error
              ? _c(
                  "EmptyContent",
                  {
                    staticClass: "comments__error",
                    attrs: { icon: "icon-error" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "desc",
                          fn: function () {
                            return [
                              _c(
                                "button",
                                {
                                  attrs: { icon: "icon-history" },
                                  on: { click: _vm.getComments },
                                },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t" +
                                      _vm._s(_vm.t("comments", "Retry")) +
                                      "\n\t\t\t\t"
                                  ),
                                ]
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      1182580137
                    ),
                  },
                  [_vm._v("\n\t\t\t" + _vm._s(_vm.error) + "\n\t\t\t")]
                )
              : _vm._e(),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }