var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    {
      staticClass: "live-relative-timestamp",
      attrs: { "data-timestamp": _vm.timestamp * 1000, title: _vm.title },
    },
    [_vm._v(_vm._s(_vm.formatted))]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }